










































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ApprovalQualityContent } from '@/types/neuhaus-website/cms/pages/approval-quality';
import AlertModule, { AlertType } from '@/store/modules/alert';
import { handleFile } from '@/utils/cms/formhelper';
import axios, { APIResponse } from '@/plugins/axios';
import { getFileObjectByUrl } from '@/utils/filehelper';

@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-admin-component-unordered-list-list': () => import(
            '@/components/cms/components/admin/unordered-list/CMSAdminComponentUnorderedListList.vue'
        ),
        'cms-admin-component-file-download-list': () => import(
            '@/components/cms/components/admin/file-download/CMSAdminComponentFileDownloadList.vue'
        ),
    }
})
export default class CMSAdminApprovalQuality extends Vue {
    // variable with form data
    private formData: ApprovalQualityContent = {
        id: -1,
        title: 'N/A',
        firstText: 'N/A',
        procedureSection: {
            title: 'N/A',
            procedures: [],
            texts: []
        },
        certificateSection: {
            title: 'N/A',
            certificates: []
        },
        rightEdgeImage: {
            id: -1,
            src: '',
            obj: undefined
        },
        bottomImage: {
            id: -1,
            src: '',
            obj: undefined
        }
    }

    // Boolean that defines wheather form is valid or not
    private formValid = false;

    /**
     * Function will be executed on component load
     * Fetches data with fetchPageContent function
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     *
    */
    private async created() {
        this.formData = await this.fetchPageContent();

        // Fetch right edge image
        if (this.formData.rightEdgeImage.src) {
            const image = this.formData.rightEdgeImage;
            const imageUrl = process.env.VUE_APP_API_URL + image.src;
            const imageExtension = imageUrl.split('.').pop();
            const imageObj = await getFileObjectByUrl(
                imageUrl, 
                `materials-rightedgeimage-${this.formData.id}.${imageExtension}`
            );

            image.obj = imageObj;
        }

        // Fetch bottom image
        if (this.formData.bottomImage.src) {
            const image = this.formData.bottomImage;
            const imageUrl = process.env.VUE_APP_API_URL + image.src;
            const imageExtension = imageUrl.split('.').pop();
            const imageObj = await getFileObjectByUrl(
                imageUrl, 
                `materials-bottomimage-${this.formData.id}.${imageExtension}`
            );

            image.obj = imageObj;
        }
    }

    /**
     * Resets the form validation
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     *
    */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void}).resetValidation();
        }
    }

    /**
     * Fetches page content from API
     *
     * @returns ApprovalQualityContent
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     *
    */
    private async fetchPageContent(): Promise<ApprovalQualityContent> {
        try {
            const response = await axios.get<APIResponse<ApprovalQualityContent>>('/cms/pages/approvalquality')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.formData;
        }
    }

    /**
     * Adds a new text to the list
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
    */
    private addProcedureText() {
        const itemText = prompt(this.$t('form.fields.procedureSection.texts').toString());

        // return when user pressed cancel
        if (itemText === null) return;

        // show alert when user input is empty
        if (itemText === '') {
            AlertModule.showAlert({ message: this.$t('alerts.errors.itemEmpty').toString(), type: AlertType.ERROR });
            return;
        }

        // show alert when item already exists
        if (this.formData.procedureSection.texts.find(t => t === itemText)) {
            AlertModule.showAlert({ message: this.$t('alerts.errors.itemAlreadyExists').toString(), type: AlertType.ERROR });
            return
        }

        // add item to array
        this.formData.procedureSection.texts.push(itemText);
    }

    /**
     * Deletes the list item with the given index
     *
     * @param itemIndex index
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
    */
    private deleteProcedureText(itemIndex: number) {
        if (this.formData.procedureSection.texts.length < itemIndex) return; 

        this.formData.procedureSection.texts.splice(itemIndex, 1);
    }

    /**
     * Saves form data
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     *
    */
    private async saveFormData() {
        try {
            // Update right edge image
            const rightEdgeImageResult = await handleFile(
                undefined,
                this.formData.rightEdgeImage.src,
                this.formData.rightEdgeImage.obj
            );
            if (
                rightEdgeImageResult.data.status !== 'success' || 
                !rightEdgeImageResult.data.data.fileUrl
            ) {
                const errorMessage = this.$t('alerts.error.' + rightEdgeImageResult.data.message).toString();
                AlertModule.showAlert({ 
                    type: AlertType.ERROR,
                    message: errorMessage 
                });

                this.formData.rightEdgeImage.src = '';
            } else {
                this.formData.rightEdgeImage.src = rightEdgeImageResult.data.data.fileUrl;
            }

            // Update bottom image
            const bottomImageResult = await handleFile(
                undefined,
                this.formData.bottomImage.src,
                this.formData.bottomImage.obj
            );
            if (
                bottomImageResult.data.status !== 'success' || 
                !bottomImageResult.data.data.fileUrl
            ) {
                const errorMessage = this.$t('alerts.error.' + bottomImageResult.data.message).toString();
                AlertModule.showAlert({ 
                    type: AlertType.ERROR,
                    message: errorMessage 
                });

                this.formData.bottomImage.src = '';
            } else {
                this.formData.bottomImage.src = bottomImageResult.data.data.fileUrl;
            }
        } catch(err) {
            console.error(err); 
        }

        // Update page data
        axios.put<APIResponse>('/cms/pages/approvalquality', this.formData)
            .then(response => {
                if (response.data.status === 'success' && response.data.data) {
                    this.resetFormValidation();
                    this.$router.push({ name: 'cmsAdminPages' });
                }
                else if (response.data.status === 'error' && response.data.message) {
                    const errorMessage = this.$t('alerts.errors.' + response.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });
                }
            });
    }
}
